/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Cookies from 'universal-cookie';
import { Helmet } from 'react-helmet';
import FindATravelAgent from './pages/FindATravelAgent';
import Home from './pages/Home';
import Agents from './pages/Agents';
import Agent from './pages/Agent';
import Header from './components/Header';
import { AppContextProvider } from './contexts/AppContext';
import ThankYou from './pages/ThankYou';
import Bio from './pages/Bio';
import Story from './pages/Story';
import Blogs from './pages/Blogs';
import Blog from './pages/Blog';
import Itnerary from './pages/Itnerary';
import AboutUs from './pages/AboutUs';
import FindYourCruise from './pages/FindYourCruise';
import Footer from './components/Footer';
import BlogCategory from './pages/BlogCategory';
import HomeAb from './pages/HomeAb';
import NotFound from './pages/NotFound';

function App() {
    const getCanonical = () => {
        if (window.location.search.includes('utm')) {
            const params = window.location.search.slice(1).split('&').reduce((acc, s) => {
                const [k, v] = s.split('=');
                if (k.includes('utm') || k.includes('gclid')) {
                    return acc;
                }
                return Object.assign(acc, { [k]: v });
            }, {});

            return `${window.location.toString().split('?')[0]}?${Object.keys(params).map((key) => `${key}=${params[key]}`).join('&')}`;
        }
        return window.location;
    };
    useEffect(() => {
        if (window.location.search.includes('utm')) {
            const params = window.location.search.slice(1).split('&').reduce((acc, s) => {
                const [k, v] = s.split('=');
                if (!k.includes('utm') && !k.includes('gclid')) {
                    return {};
                }
                return Object.assign(acc, { [k]: v });
            }, {});
            const cookies = new Cookies();
            cookies.set('google-utm', JSON.stringify(params),
                { expires: new Date(Date.now() + (60 * 60 * 24 * 1000 * 7)), path: '/' });
        }
    }, []);

    return (
        <AppContextProvider>
            <Router forceRefresh>

                <div className="bg-gray-verylight  mx-auto">
                    <Helmet>
                        <link rel="canonical" href={getCanonical()} />
                    </Helmet>
                    <Header />
                    <div className="w-full">
                        <Switch>

                            <Route path="/" component={HomeAb} exact />
                            <Route path="/home" component={Home} exact />
                            <Route path="/find_travel_agent" component={FindATravelAgent} exact />
                            <Route path="/find_your_cruise" component={FindYourCruise} exact />
                            <Route path="/agents" component={Agents} exact />
                            <Route path="/agent/:id" component={Agent} exact />
                            <Route path="/agent/:id/bio/:bioId" component={Bio} exact />
                            <Route path="/agent/:id/story/:storyId" component={Story} exact />
                            <Route path="/agent/:id/bio/:bioId/story/:storyId" component={Story} exact />
                            <Route path="/travel_information" component={Blogs} exact />
                            <Route path="/travel_information_category" component={BlogCategory} exact />
                            <Route path="/travel_information/:id/:title" component={Blog} exact />
                            <Route path="/agent/:id/itinerary/:itneraryId" component={Itnerary} exact />
                            <Route path="/agent/:id/bio/:bioId/itinerary/:itneraryId" component={Itnerary} exact />
                            <Route path="/thankYou" component={ThankYou} exact />
                            <Route path="/about_us" component={AboutUs} exact />

                            <Route component={NotFound} exact />
                        </Switch>
                    </div>
                    <Footer />

                </div>
            </Router>
        </AppContextProvider>
    );
}

export default App;
